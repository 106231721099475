import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as S from "../components/Post/styled"

const AboutPage = () => (
  <Layout>
    <SEO title="Sobre mim" />
    <S.MainContent>
      <h1>Sobre mim</h1>
      <p>Olá, meu nome é Igor Balteiro, sou carioca e trabalho com desenvolvimento de software, além de estar aprendendo a fazer bordado à mão livre.</p>
      <p>Comecei a trabalhar com tecnologia da informação (TI) em 2015 e trabalhei em 3 grandes empreas até hoje: <a href="https://www.ibm.com/br-pt" target="_blank" rel="noopener noreferrer">IBM</a>, <a href="https://www.thoughtworks.com/" target="_blank" rel="noopener noreferrer">ThoughtWorks</a> (nessa oportunidade morei em Porto Alegre por um pouco mais de 2 anos e meio) e <a href="https://www.stone.com.br/" target="_blank" rel="noopener noreferrer">Stone</a>.</p>
      <p>Antes de trabalhar com TI, fiz técnico em Meteorologia pelo CEFET/RJ e cursei alguns muitos períodos do mesmo curso na UFRJ antes de trocar de área e ir para a UNIRIO (<span className="heart">&#9829;</span>) fazer Sistemas de Informação.</p>
      <p>Comecei um mestrado em Ciência da Computação na UFRGS com foco em Visualização de Dados e <strong>AINDA</strong> não consegui concluir (falta terminar a dissertação). Além disso, estou terminando uma especialização em Ciência de Dados e Big Data pela PUC-MINAS.</p>
      <p>Amo trabalhar com Front End (a melhor parte, para mim, é poder mostrar meu trabalho para família e amigos) e adoro explorar dados e as diferentes formas de visualizá-los.</p>
      <h2>Contato</h2>
      <p>
        Você pode entrar com contato comigo através do meu <a href="https://www.linkedin.com/in/igor-campos-bb4160113/" target="_blank" rel="noopener noreferrer">LinkedIN</a>.
      </p>
    </S.MainContent>
  </Layout>
)

export default AboutPage
